<template>
	<div class="product-details">
		<div class="recommend-slider py-1">
			<div class="osahan-slider-item m-2">
				<div
					class="w-full h-56 bg-cover mx-auto shadow-sm rounded"
					:style="
						`background-image: url(${$constants.STORAGE_PATH}${product.image})`
					"
					alt="Responsive image"
				/>
			</div>
		</div>
		<a
			@click="addToCart"
			class="btn btn-success mx-2 uppercase bolder text-white float-left"
			style="font-size: 1.5em;padding: 0.4em 1.5em;text-transform: initial;"
		>
			Order
		</a>
		<div class="px-3 mb-4 bg-white">
			<div class="d-flex align-items-center">
				<a class="ml-auto">
					<div
						class="input-group input-spinner ml-auto cart-items-number"
					>
						<div class="input-group-prepend">
							<button
								class="btn btn-success btn-sm"
								@click="increment"
								type="button"
							>
								+
							</button>
						</div>
						<input
							type="text"
							class="form-control"
							style="font-size: 20px; height: 100%;"
							v-model="quantity"
						/>
						<div class="input-group-append">
							<button
								class="btn btn-success btn-sm"
								@click="decrement"
								type="button"
							>
								−
							</button>
						</div>
					</div>
				</a>
			</div>
		</div>
		<div class="details">
			<div class="p-3">
				<h5 class="uppercase">Ingredients</h5>
				<p
					v-if="product.ingrediants"
					class="text-muted small p-4 bg-grey-100 break-all rounded"
					v-html="product.ingrediants"
				/>
				<p
					v-else
					class="text-muted small p-4 bg-grey-100 break-all rounded"
				>
					AUCUNE INFORMATION
				</p>
			</div>
			<div class="p-3">
				<h5 class="uppercase">Instructions</h5>
				<p
					v-if="product.preparation"
					class="text-muted small p-4 bg-grey-100 break-all rounded"
					v-html="product.preparation"
				/>
				<p
					v-else
					class="text-muted small p-4 bg-grey-100 break-all rounded"
				>
					AUCUNE INFORMATION
				</p>
			</div>
			<div class="p-3">
				<h5 class="uppercase">Nutrition</h5>
				<p
					v-if="product.nurturingInfo"
					class="text-muted small p-4 bg-grey-100 break-all rounded"
					v-html="product.nurturingInfo"
				/>
				<p
					v-else
					class="text-muted small p-4 bg-grey-100 break-all rounded"
				>
					AUCUNE INFORMATION
				</p>
			</div>
		</div>
		<br />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	props: ['product'],
	data: () => ({
		quantity: 1
	}),
	mounted: function() {
		this.quantity = this.getCommandedPrice(this.product.prices[0].id);
		this.quantity = this.quantity === 0 ? 1 : this.quantity;
	},
	methods: {
		addToCart: function() {
			this.$store.dispatch('cart/addCommandToCartNoCheck', {
				price: this.product.prices[0],
				products: [this.product],
				quantity: this.quantity
			});
			this.$notify.success({
				type: 'success',
				title: `Successfuly Added ${this.quantity} X ${this.product.label}`
			});
			this.$emit('submited');
		},
		increment: function() {
			if (this.quantity > 99) this.quantity = 99;
			else this.quantity += 1;
		},
		decrement: function() {
			if (this.quantity == 0) this.quantity = 0;
			else this.quantity -= 1;
		}
	},
	computed: {
		...mapGetters({
			getCommandedPrice: 'cart/priceQuantity'
		})
	}
};
</script>

<style></style>
